import { createSlice } from "@reduxjs/toolkit";
import { switchDashboardSliceStateType } from "../Interfaces/Redux/sliceStateTypes";
import moment from "moment";

const initialState: switchDashboardSliceStateType = {
    migrationDate: "",
    //CHANGE TO 26
    tutorialStartDate: moment("2024-11-25", "YYYY/MM/DD").format(),
    tutorialEndDate: moment("2024-12-10", "YYYY/MM/DD").format(),
    seenTutorial: {
        main: false,
        payments: false,
        customers: false,
        "bulk-delivery": false,
        posms: false,
    },
    //have to add seen to each page?
};

export const switchDashboardSlice = createSlice({
    name: "switchDashboard",
    initialState,
    reducers: {
        setDate: (state, action) => {
            state.migrationDate = action.payload;
        },
        setSeenTutorial: (state, action) => {
            state.seenTutorial = {
                ...state.seenTutorial,
                [action.payload.section]: action.payload.value,
            };
        },
    },
});

export const { setDate, setSeenTutorial } = switchDashboardSlice.actions;
export default switchDashboardSlice.reducer;
