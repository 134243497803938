import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import config from "../config.json";
import { LoginApi, MFACheck } from "@paylands-frontend/api/dist/LoginAPI";
import { ClearLocalStorage } from "../utils/ClearLocalStorage";
import {
    ForgotPassword,
    UpdatePassword,
} from "@paylands-frontend/api/dist/Password";
import { ResetPassword } from "@paylands-frontend/api/dist/ResetPassword";
import { AuthSliceStateType } from "../Interfaces/Redux/sliceStateTypes";
import i18next from "i18next";

const initialState: AuthSliceStateType = {
    user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user") as string)
        : null,
    isAuthenticated:
        localStorage.getItem("user") && localStorage.getItem("oauth")
            ? true
            : false,
    viewAs: {
        uuid: JSON.parse(localStorage.getItem("viewAs") as string)?.uuid,
        name: JSON.parse(localStorage.getItem("viewAs") as string)?.name,
        hasChildren: JSON.parse(localStorage.getItem("viewAs") as string)
            ?.hasChildren,
    },
    loading: false,
    errors: null,
    scope: localStorage.getItem("oauth")
        ? JSON.parse(localStorage.getItem("oauth") as string).scope.split(" ")
        : null,
};

/** Creating a thunk for async calls, thunk gives a possibility to manage Promise
Saving all data (user, oauth, viewAs) to localStorage - it is used for api calls in future,
Saving user data or errors to Redux store */
export const fetchUserData = createAsyncThunk(
    "auth/request",
    async (
        credentials: {
            username: string;
            password: string;
            language: string;
        },
        { rejectWithValue }
    ) => {
        const response = await LoginApi(config.SERVER_URL, credentials)
            .then((response) => {
                localStorage.setItem(
                    "oauth",
                    JSON.stringify(response.data.oauth)
                );
                localStorage.setItem(
                    "user",
                    JSON.stringify(response.data.user)
                );
                if (response.data.user.language) {
                    i18next.changeLanguage(
                        response.data.user.language?.toLowerCase()
                    );
                }
                if ("SANDBOX" === response.data.user.environment) {
                    localStorage.setItem(
                        "viewAs",
                        JSON.stringify({
                            uuid: response.data.user.client_sandbox_uuid,
                            name: response.data.user.client_sandbox_name,
                        })
                    );
                } else {
                    localStorage.setItem(
                        "viewAs",

                        JSON.stringify({
                            uuid: response.data.user.client_production_uuid,
                            name: response.data.user.client_production_name,
                        })
                    );
                }
                return response.data;
            })
            .catch((error) => {
                return rejectWithValue(error.response.data);
            });

        return response;
    }
);

/** Thunk for forgot password in order to set loading states and handle response messages */
export const postForgotPassword = createAsyncThunk(
    "auth/forgotPassword",
    async (
        credentials: {
            email: string;
            passwordRetrievalReason: "EXPIRED" | "FORGOTTEN";
        },
        { rejectWithValue }
    ) => {
        const response = await ForgotPassword(config.SERVER_URL, credentials)
            .then((response) => response.data)
            .catch((error) => rejectWithValue(error.response.data));
        return response;
    }
);

export const postMFACode = createAsyncThunk(
    "auth/MFACode",
    async (
        credentials: {
            email: string;
            code: string;
        },
        { rejectWithValue }
    ) => {
        const response = await MFACheck(config.SERVER_URL, credentials)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return rejectWithValue(error.response.data);
            });
        return response;
    }
);

/** Thunk for reset password in order to set loading states and handle response messages */
export const putResetPassword = createAsyncThunk(
    "auth/resetPassword",
    async (params: {
        email: string;
        confirm_password: string;
        password: string;
        token: string;
    }) => {
        const response = await ResetPassword(config.SERVER_URL, params).catch(
            (error) => error.response
        );
        return response.data;
    }
);

export const putUpdatePassword = createAsyncThunk(
    "auth/updatePassword",
    async (
        params: {
            credentials: {
                email: string;
                old_password: string;
                password: string;
                confirm_password: string;
                remember: boolean;
            };
            userUUID: string;
            signature: string;
            headers: {
                accessToken: string;

                viewAs: string;
            };
        },
        { rejectWithValue }
    ) => {
        const response = await UpdatePassword(
            config.SERVER_URL,
            params.userUUID,
            params.headers,
            { ...params.credentials, signature: params.signature }
        )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return rejectWithValue(error.response.data.message);
            });
        return response;
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: () => {
            new ClearLocalStorage().clear();
            const logoutState: AuthSliceStateType = {
                user: null,
                isAuthenticated: false,
                viewAs: {
                    uuid: null,
                    name: null,
                    hasChildren: false,
                },
                loading: false,
                errors: null,
                scope: [],
            };
            return logoutState;
        },
        setEnvironment: (state, action) => {
            const userString = localStorage.getItem("user");
            let user;
            if (userString !== null) {
                user = JSON.parse(userString);
                user.environment = action.payload;
            }
            localStorage.setItem("user", JSON.stringify(user));
            if (state.user) state.user.environment = action.payload;
        },
        setViewAs: (state, action) => {
            localStorage.setItem("viewAs", JSON.stringify(action.payload));
            state.viewAs = action.payload;
        },
        resetErrors: (state) => {
            state.errors = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserData.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchUserData.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload.user;
                state.isAuthenticated = true;
                state.scope = action.payload.oauth.scope.split(" ");

                state.viewAs = {
                    ...state.viewAs,
                    uuid:
                        action.payload.user.environment === "SANDBOX"
                            ? action.payload.user.client_sandbox_uuid
                            : action.payload.user.client_production_uuid,
                    name:
                        action.payload.user.environment === "SANDBOX"
                            ? action.payload.user.client_sandbox_name
                            : action.payload.user.client_production_name,
                };
            })
            .addCase(fetchUserData.rejected, (state, action) => {
                state.loading = false;
                state.user = null;
                state.errors = action.payload;
                state.isAuthenticated = false;
            })
            //MFA Code thunk reducers
            .addCase(postMFACode.pending, (state) => {
                state.loading = true;
            })
            .addCase(postMFACode.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(postMFACode.rejected, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            })
            //Forgot Password thunk reducers
            .addCase(postForgotPassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(postForgotPassword.fulfilled, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            })
            .addCase(postForgotPassword.rejected, (state, action) => {
                state.loading = false;
                state.errors = action.error.message;
            })
            //Reset Password thunk reducers
            .addCase(putResetPassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(putResetPassword.fulfilled, (state, action) => {
                state.loading = false;
                state.errors =
                    action?.payload?.code === 200
                        ? `SUCCESS`
                        : action?.payload?.details_key;
            })
            .addCase(putResetPassword.rejected, (state, action) => {
                state.loading = false;
                state.errors = action.error?.message;
            })
            //Update Password thunk
            .addCase(putUpdatePassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(putUpdatePassword.fulfilled, (state, action) => {
                state.loading = false;
                state.errors =
                    action.payload.code === 200
                        ? `SUCCESS`
                        : action.payload.message;
            })
            .addCase(putUpdatePassword.rejected, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            });
    },
});
export const { logout, setEnvironment, resetErrors, setViewAs } =
    authSlice.actions;
export default authSlice.reducer;
