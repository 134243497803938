import { createSlice } from "@reduxjs/toolkit";
import { DatatableSliceStateType } from "../Interfaces/Redux/sliceStateTypes";

const initialState: DatatableSliceStateType = {
    hiddenColumns: [
        "service_uuid",
        "additional",
        "transaction_error",
        "reference",
    ],
    rowsPerPage: 25,
    sortBy: [{ desc: true, id: "created_at" }],
    columnsOrder: [
        "uuid",
        "client",
        "created_at",
        "amount",
        "status",
        "operative",
        "source_type",
        "service",
        "customer",
        "service_uuid",
        "reference",
        "additional",
    ],
};

export const ordersTablePreferenceSlice = createSlice({
    name: "ordersTablePreference",
    initialState,
    reducers: {
        setOrdersHiddenColumns: (state, action) => {
            state.hiddenColumns = action.payload;
        },
        setOrdersRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setOrdersSortBy: (state, action) => {
            state.sortBy = action.payload;
        },
        setOrdersColumnOrder: (state, action) => {
            state.columnsOrder = action.payload;
        },
    },
});
export const {
    setOrdersHiddenColumns,
    setOrdersRowsPerPage,
    setOrdersSortBy,
    setOrdersColumnOrder,
} = ordersTablePreferenceSlice.actions;
export default ordersTablePreferenceSlice.reducer;
